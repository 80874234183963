import axios from "axios";

import { commonHandler } from "./axiosService";

const deviceAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DEVICES_API,
});

export const callDevice = commonHandler(deviceAxiosInstance);

export const deviceRoutes = {
  listMyDevices: "/list-my-devices",
  listAllDevices: "/list-all-devices",
  listAllDevicesWithSubStatus: "/list-all-devices-sub-status",
  createDevice: "/create",
  removeDevice: "/remove",
  upsertDevice: "upsert-device",
  subDevice: "/subscribe",
  subDeviceForUser: "/subscribe-user",
  unsubDeviceForUser: "unsubscribe-user",
  unSubDevice: "/unsubscribe",
  assignProperty: "/assign-property",
  unAssignProperty: "/unassign-property",
  getHouseProperty: "/device-property",
  getClientSecret: "/get-client-secret",
  regenerateClientSecret: "/regenerate-client-secret",
};
