import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  width: {
    xs: "100%", // For mobile (xs breakpoint)
    sm: "50%", // For tablets and larger (sm breakpoint)
  },
};

const HouseDescriptionModal = ({
  open,
  onClose,
  property,
  onSaveDescription,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setEditedDescription(property.description || "");
  }, [property]);

  // Handle edit toggle
  const handleEditToggle = () => {
    if (isEditing) setEditedDescription(property.description);
    setIsEditing(!isEditing);
  };
  function onSuccessSave() {
    setIsEditing(false);
    setSnackbarOpen(true);
  }
  // Handle save description
  const handleSaveDescription = async () => {
    await onSaveDescription(editedDescription, onSuccessSave);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            onClose(); // Call the onClose only if the reason is not a backdrop click
          }
        }}
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6" component="h2" gutterBottom>
              Property Name: {property.name}
            </Typography>
            {/* Close button */}
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body1" gutterBottom>
            Description:
          </Typography>
          <Typography
            variant="body2"
            component={isEditing ? "textarea" : "p"}
            sx={{
              width: "100%",
              height: "150px",
              p: 2,
              border: isEditing ? "1px solid #ccc" : "none",
              borderRadius: "4px",
              backgroundColor: isEditing ? "#f9f9f9" : "transparent",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              overflowY: "scroll",
            }}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          >
            {!isEditing && property.description}
          </Typography>
          <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              startIcon={isEditing ? <CancelIcon /> : <EditIcon />}
              onClick={handleEditToggle}
            >
              {isEditing ? "Cancel" : "Edit"}
            </Button>
            {isEditing && (
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSaveDescription}
              >
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Snackbar to show "Saved!" message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Description saved!"
      />
    </>
  );
};

export default HouseDescriptionModal;
