import React, { useEffect, useState } from "react";
import Topbar from "../common/Topbar";
import DeviceHub from "@mui/icons-material/DeviceHub";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import HouseIcon from "@mui/icons-material/House";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { useUserInfo } from "../../../context/useUserInfo";
import { useApis } from "../../../context/apiContext";
import { authHeader } from "../../../services/httpServices/axiosService";
import { generateRandomString } from "../../../function/utility";
import { delay } from "@okta/okta-auth-js";
import { propertyRoutes } from "../../../services/httpServices/property";
import NewPropertyCard from "./propertyCard/NewPropertyCard";
import PropertyCard from "./propertyCard/PropertyCard";
import BadgeIcon from "@mui/icons-material/Badge";
import axios from "axios";
import HouseDescriptionModal from "./modals/HouseDescriptionModal";

export default function Properties({
  useSelectButton = false,
  onSelectButton = () => {},
}) {
  const baseSelectedProperty = { id: -1, description: "" };
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [selectedProperty, setSelectedProperty] =
    useState(baseSelectedProperty);

  const handleOpenDescriptionModal = (property) => {
    setSelectedProperty(property);
    setOpenDescriptionModal(true);
  };

  const handleCloseDescriptionModal = () => {
    setSelectedProperty(baseSelectedProperty);
    setOpenDescriptionModal(false);
  };

  const baseNewProperty = {
    address: "",
    name: "",
  };
  const { callProperty } = useApis();
  const [userInfo, setUserInfo] = useUserInfo();
  const [newProperty, setNewProperty] = useState(null);
  const isAdmin = userInfo.isAdmin;

  const [editIndex, setEditIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState({
    address: "",
  });
  const icons = {
    address: <HomeIcon sx={{ color: "#388E3C" }} />,
    name: <BadgeIcon sx={{ color: "#FF9800" }} />,
  };

  const [archiveProperties, setArchiveProperties] = useState([]);
  const [propeties, setPropetis] = useState([]);

  useEffect(() => {
    if (editIndex !== -1) setArchiveProperties(propeties);
  }, [editIndex]);

  async function getAllProperties(params) {
    const propertyApi = callProperty();
    propertyApi.try = async (api) => {
      const { data } = await api.get(propertyRoutes["getAllProperties"], {
        ...authHeader(),
        params,
      });

      setPropetis(data);
    };
    propertyApi.run();
  }

  useEffect(() => {
    if (userInfo.userLoaded) search(searchQuery);
  }, [userInfo]);

  const searchField = (
    <TextField
      label="Search Address"
      value={searchQuery.address}
      onChange={(e) => updateSearchQuery("address", e.target.value)}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => {
              search(searchQuery);
            }}
          >
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );

  function updateSearchQuery(key, value) {
    const newQuery = { ...searchQuery, [key]: value };
    setSearchQuery(newQuery);
    return newQuery;
  }
  function search(query) {
    getAllProperties(query);
  }
  function handlePropertyTextChange(index, key, value) {
    const propertiesCopy = [...propeties];
    const thisProperty = { ...propertiesCopy[index] };
    thisProperty[key] = value;

    propertiesCopy[index] = thisProperty;
    setPropetis(propertiesCopy);
  }
  function handleCancleEdit() {
    setPropetis(archiveProperties);
    setEditIndex(-1);
  }
  async function upsertProperty(name, address, propertyId = null) {
    const propertyApi = callProperty();
    const postObject = { name, address };
    if (propertyId != null) postObject.id = propertyId;

    propertyApi.try = async (api) => {
      await api.post(
        propertyRoutes["upsertProperty"],
        postObject,
        authHeader()
      );
      toast.success("changes saved");
      await delay(1000);
      window.location.reload();
    };
    await propertyApi.run();
  }
  function handleSaveEdit() {
    const property = propeties[editIndex];
    upsertProperty(property.name, property.address, property.propertyId);
  }

  function handleDeleteProperty() {
    const { propertyId } = propeties[editIndex];
    const propertyApi = callProperty();
    propertyApi.try = async (api) => {
      const url = `${propertyRoutes["deleteProperty"]}/${propertyId}`;

      await api.delete(url, authHeader());
      toast.error("property deleted");
      await delay(1000);
      window.location.reload();
    };
    propertyApi.run();
  }
  async function handleSaveDescription(newDescription, onSuccess) {
    const propertyApi = callProperty();
    propertyApi.try = async (api) => {
      const { data } = await api.post(
        propertyRoutes["setHouseDescription"],
        { id: selectedProperty.propertyId, description: newDescription },
        authHeader()
      );
      setSelectedProperty(data);
      const index = propeties.findIndex(
        (property) => property.propertyId === selectedProperty.propertyId
      );
      if (index !== -1) {
        console.log("found it ");

        const updatedProperties = [...propeties];
        updatedProperties[index] = {
          ...updatedProperties[index],
          description: newDescription,
        };
        setPropetis(updatedProperties);
      }
      onSuccess();
    };
    await propertyApi.run();
  }
  function handleNewPropertyTextChange(key, value) {
    setNewProperty((perv) => ({ ...perv, [key]: value }));
  }
  function handleCancelNewProperty() {
    setNewProperty(null);
  }
  function handleSaveNewProperty() {
    upsertProperty(newProperty.name, newProperty.address);
  }
  return (
    <div>
      {!useSelectButton && (
        <Topbar
          icon={<HouseIcon />}
          header="Properties"
          caption="Manage Properties"
        ></Topbar>
      )}
      <Box display="flex" justifyContent="center" mb={1} mt={1}>
        {searchField}
      </Box>

      <Box p={2}>
        {isAdmin && !newProperty && !useSelectButton && (
          <Button
            onClick={() => {
              setNewProperty(baseNewProperty);
            }}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add new property
          </Button>
        )}
      </Box>

      <Box>
        <Grid container flexWrap={"wrap"} spacing={2} p={1}>
          {newProperty && !useSelectButton && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <NewPropertyCard
                property={newProperty}
                onTextChange={handleNewPropertyTextChange}
                onCancelEdit={handleCancelNewProperty}
                onSaveEdit={handleSaveNewProperty}
                icons={icons}
              />
            </Grid>
          )}
          {propeties.map((property, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
              <PropertyCard
                openHouseDescriptionModal={handleOpenDescriptionModal}
                useSelectButton={useSelectButton}
                icons={icons}
                onSelectButton={onSelectButton}
                onCancelEdit={handleCancleEdit}
                onSaveEdit={handleSaveEdit}
                onDeleteProperty={handleDeleteProperty}
                index={i}
                editIndex={editIndex}
                setEditIndex={setEditIndex}
                property={property}
                onTextChange={handlePropertyTextChange}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <HouseDescriptionModal
        open={openDescriptionModal}
        onClose={handleCloseDescriptionModal}
        property={selectedProperty}
        onSaveDescription={handleSaveDescription} // Pass your save function here if necessary
      />
    </div>
  );
}
