import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Snackbar,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "100%", // For mobile (xs breakpoint)
    sm: "50%", // For tablets and larger (sm breakpoint)
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ClientSecretModal = ({ open, onClose, device, onRegenerateSecret }) => {
  const [isCopied, setIsCopied] = useState(false);

  // Copy client secret to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(device.clientSecret);
    setIsCopied(true);
  };

  // Handle regenerate client secret
  const handleRegenerate = () => {
    onRegenerateSecret(device.deviceId);
    setIsCopied(false); // Reset copied state
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom>
            Client Secret
          </Typography>
          <TextField
            fullWidth
            value={device.clientSecret}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mb: 2 }}
          />
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={handleCopy}
            >
              Copy
            </Button>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={handleRegenerate}
            >
              Regenerate
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar to show "Copied!" message */}
      <Snackbar
        open={isCopied}
        autoHideDuration={3000}
        onClose={() => setIsCopied(false)}
        message="Copied to clipboard!"
      />
    </>
  );
};

export default ClientSecretModal;
