import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
} from '@mui/material';
import { useApis } from '../../../context/apiContext';
import { authHeader } from '../../../services/httpServices/axiosService';
import { useUserInfo } from '../../../context/useUserInfo';
import { chatRoutes } from '../../../services/httpServices/chat';
import FolderIcon from '@mui/icons-material/Folder';
import { useNavigate } from 'react-router-dom';

const Tickets = () => {
  const nav = useNavigate();

  const [userInfo] = useUserInfo();

  const { callChat } = useApis();
  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTickets, setTotalTickets] = useState(0);
  const [showMyTickets, setShowMyTickets] = useState(false);

  useEffect(() => {
    fetchTickets();
  }, [userInfo, page, rowsPerPage, showMyTickets]);

  const fetchTickets = async () => {
    const chatApi = callChat();

    chatApi.try = async (api) => {
      const params = {
        page: page + 1, // MUI TablePagination is zero-indexed
        limit: rowsPerPage,
      };

      if (userInfo.isAdmin && showMyTickets) {
        params.assigned_to = userInfo.userId;
      }
      const url = userInfo.isAdmin
        ? chatRoutes['adminGetTickets']
        : chatRoutes['customerGetTickets'];
      const response = await api.get(url, {
        params,
        ...authHeader(),
      });

      setTickets(response.data.tickets);
      setTotalTickets(response.data.meta.totalTickets);
    };

    await chatApi.run();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const toggleShowMyTickets = () => {
    setShowMyTickets((prevShowMyTickets) => !prevShowMyTickets);
  };
  function claimTicket(_id) {
    const chatApi = callChat();

    chatApi.try = async (api) => {
      await api.post(
        chatRoutes['assignTicket'],
        {
          _id,
        },
        authHeader()
      );
      await fetchTickets();
    };

    chatApi.run();
  }

  function navToTicket(id) {
    nav(id); //relative path, adds id to the end
  }
  function navToNewTicket() {
    nav('new-ticket');
  }
  return (
    <Paper className="mx-2">
      {userInfo.isAdmin && (
        <Button
          variant="contained"
          color="primary"
          onClick={toggleShowMyTickets}
        >
          {showMyTickets ? 'Show All Tickets' : 'Show My Tickets'}
        </Button>
      )}
      {!userInfo.isAdmin && (
        <Button onClick={navToNewTicket} variant="contained" color="primary">
          create new ticket
        </Button>
      )}
      <TableContainer>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              {userInfo.isAdmin && <TableCell>Created By</TableCell>}
              {userInfo.isAdmin && <TableCell>Customer Email</TableCell>}
              <TableCell>Status</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Assigned To</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell> </TableCell>
              {/* Add more table headers as needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.length > 0 &&
              tickets.map((ticket, id) => {
                const fullName = `${ticket.customerInfo.firstName} ${ticket.customerInfo.lastName}`;
                const createdDate = new Date(ticket.createdAt).toDateString();
                const assignedToFullName =
                  ticket.employeeInfo &&
                  `${ticket.employeeInfo.firstName} ${ticket.employeeInfo.lastName}`;
                return (
                  <TableRow key={id}>
                    <TableCell sx={{ maxWidth: 100 }}>{ticket.title}</TableCell>
                    {userInfo.isAdmin && <TableCell>{fullName}</TableCell>}
                    {userInfo.isAdmin && (
                      <TableCell>{ticket.customerInfo.email}</TableCell>
                    )}
                    <TableCell>{ticket.status}</TableCell>
                    <TableCell>{ticket.category.title}</TableCell>
                    <TableCell>
                      {assignedToFullName ||
                        (ticket.status !== 'CLOSED' && userInfo.isAdmin && (
                          <Button
                            onClick={() => claimTicket(ticket._id)}
                            variant="outlined"
                            color="info"
                          >
                            Claim Ticket
                          </Button>
                        ))}
                    </TableCell>
                    <TableCell>{createdDate}</TableCell>
                    <TableCell
                      onClick={() => navToTicket(ticket._id)}
                      className="pointer"
                    >
                      <FolderIcon color="info" />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={totalTickets}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Tickets;
