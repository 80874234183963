import { Button, Chip, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useUserInfo } from "../../../../context/useUserInfo";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate } from "react-router-dom";
import DeviceCardListItems from "./DeviceCardListItems";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
export default function DeviceCard({
  index,
  onDeviceCustomerSelect,
  onDeviceAddressSelect,
  onDeviceClientSecretSelect,
  icons,
  device,
  editIndex,
  onTextChange,
  setEditIndex,
  onCancelEdit,
  onSaveEdit,
  onDeleteDevice,
  onDeviceSub,
  onViewGraphs,
}) {
  device = { ...device };

  const [userInfo, setUserInfo] = useUserInfo();

  const isAdmin = userInfo.isAdmin;

  const currentlyEditing = editIndex === index;

  const [manageElement, setManageElement] = useState(null);

  const handleMenu = (event) => {
    setManageElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setManageElement(null);
  };

  const manageMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={manageElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(manageElement)}
      onClose={handleCloseMenu}
    >
      {device.address && (
        <MenuItem
          onClick={() => {
            onViewGraphs(device.deviceId);
          }}
        >
          <Button color="secondary" startIcon={<AutoGraphIcon />}>
            view graphs
          </Button>
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem onClick={() => onDeviceCustomerSelect(index)}>
          <Button
            color={device.customerId ? "error" : "warning"}
            startIcon={<PersonIcon />}
          >
            {device.customerId ? "unassign customer" : "assign customer"}
          </Button>
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem
          onClick={() => {
            onDeviceClientSecretSelect(index);
          }}
        >
          <Button startIcon={<KeyIcon />}>client secret</Button>
        </MenuItem>
      )}

      {isAdmin && !currentlyEditing && editIndex === -1 && (
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            setEditIndex(index);
          }}
        >
          <Button color="info" startIcon={<EditIcon />}>
            edit
          </Button>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        flexDirection: "column",
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
        position: "relative",
        width: "100%",
      }}
    >
      {manageMenu}

      {isAdmin && (
        <IconButton
          style={{ cursor: "pointer" }}
          color="error"
          onClick={onDeleteDevice}
          disabled={!currentlyEditing}
          sx={{
            width: "40px",
            height: "40px",
            opacity: currentlyEditing ? "1" : "0",
            alignSelf: "flex-end",
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      )}
      <DeviceCardListItems
        onDeviceAddressSelect={onDeviceAddressSelect}
        icons={icons}
        deviceInfo={device}
        currentlyEditing={currentlyEditing}
        index={index}
        onTextChange={onTextChange}
      />
      <div
        className="mb-2"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {!currentlyEditing && (
          <Button
            endIcon={<MoreVertIcon />}
            color="info"
            onClick={handleMenu}
            variant="outlined"
          >
            Manage
          </Button>
        )}
        {currentlyEditing && (
          <>
            <Button
              variant="outlined"
              color="error"
              className="mx-1"
              onClick={onCancelEdit}
              startIcon={<CancelIcon />}
            >
              cancel
            </Button>
            <Button
              onClick={onSaveEdit}
              variant="outlined"
              color="success"
              startIcon={<SaveIcon />}
            >
              save
            </Button>
          </>
        )}
      </div>
    </Paper>
  );
}
